import React, { useState, useLayoutEffect, useEffect } from 'react';
import './App.css';
import Screen from './components/Screen';
import Fader from './components/Fader';
import Login from './components/Login';
import * as smoothscroll from 'smoothscroll-polyfill';
import { useSelector, useDispatch } from 'react-redux';
import { stopGame, selectGameState, selectGameURL, selectAuthenticating } from './store/screenSlice';
import axios from 'axios';

export default function App() {
  
  //just to get the userId parameter for the login account link
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    const data = {
        token: token
    };
    axios.post(`${process.env.REACT_APP_API_URL}?call=login`, data).then(response => {
      if(response.data.result===1) { //success
        if(response.data.userId && response.data.userId!==undefined) localStorage.setItem('msisdn', JSON.stringify(response.data.userId));
      }
    });
  });

  const dispatch = useDispatch();
  // disable safari bounce
  document.ontouchmove = function (event) {
    event.preventDefault();
  };

  // mobile viewport fix
  const setViewport = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  window.onresize = function () {
    setViewport();
  };
  useLayoutEffect(() => {
    smoothscroll.polyfill();
    setViewport();
  });

  const [startApp, setStartApp] = useState(false);
  const gameState = useSelector(selectGameState);
  const gameURL = useSelector(selectGameURL); 
  const isAutenticating = useSelector(selectAuthenticating);

  return (
    <div className="App">

      {startApp ? (
        <>
        <Fader></Fader>
         { !gameState && !isAutenticating ? ( <Screen/> ) : (
          <>
            { isAutenticating ? ( <Login></Login> ) : (
          <div className="holderGame">
            <div className='gameBar'>
              <img src={process.env.PUBLIC_URL + '/images/return_button.png'} className="quitButton" alt="quit" onClick={() => dispatch(stopGame())}></img>
            </div>
            <iframe src={gameURL} title="test" className='gameFrame'
                scrolling="no" 
                frameBorder="0"></iframe>
          </div> )}
          </>
          )
        }
        </>
      ) : (
        <div className="homeDiv">
          <div>
            <img src={process.env.PUBLIC_URL + '/images/logo.png'} className="logo" alt="logo" onClick={() => setStartApp(true)}></img>
          </div>
          <div>
            <img src={process.env.PUBLIC_URL + '/images/home_button.png'} className="homeButton" alt="button" onClick={() => setStartApp(true)}></img>
          </div>
        </div>
      )}
    </div>
  );
}
