import React from "react";
import PropTypes from "prop-types";
import classes from "./Drawer.module.css";
import { changeAnchor } from "../../common/change-anchor";
import { TwitterShareButton, TwitterIcon, EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon } from "react-share";

export const Drawer = (props:any) => {
  const { open, anchor, onClose, gameUrl } = props;
  const {
    drawer,
    animate,
    hidden,
    overlay,
    overlayOpen,
    overlayHidden
  } = classes;

  return (
    <>
      <div
        className={`${overlay} ${!open && overlayHidden} ${
          open && overlayOpen
        }`}
        onClick={onClose}
        aria-hidden="true"
      />
      <div
        tabIndex={-1}
        className={`${drawer} ${open && animate} ${
          !open && hidden
        } ${changeAnchor(anchor, classes)}`}
      >
        <FacebookShareButton style={{marginTop: '20px', color:'#000'}} quote="Play this game! Is amazing" url={"https://za.playgo.games" + gameUrl}>
          <FacebookIcon size={40} round={true}></FacebookIcon>
        </FacebookShareButton>

        <WhatsappShareButton style={{color:'#000'}} url={"https://za.playgo.games" + gameUrl}>
          <WhatsappIcon size={40} round={true}></WhatsappIcon>
        </WhatsappShareButton>

        <TwitterShareButton style={{color:'#000'}} url={"https://za.playgo.games" + gameUrl}>
          <TwitterIcon size={40} round={true}></TwitterIcon>
        </TwitterShareButton>

        <EmailShareButton style={{color:'#000'}} url={"https://za.playgo.games" + gameUrl}>
          <EmailIcon size={40} round={true}></EmailIcon> 
        </EmailShareButton>
      
      </div>
    </>
  );
};

Drawer.propTypes = {
  open: PropTypes.bool.isRequired,
  anchor: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  gameUrl: PropTypes.string.isRequired
};